import React from "react";
import { graphql, Link } from "gatsby";
import {
    Avis,
    FormHero,
    Hero,
    Layout,
    ListCheck,
    MetaModulo,
    Separator,
    StickyBar,
    Title,
    Wrapper,
} from "../components/";
import _ from "lodash";
import Arrow from "../assets/svg/arrow_link.svg";
import styled from "styled-components";
import breakpoint from "../theme/breakpoints";
import {Helmet} from "react-helmet";

export const query = graphql`
    query ($id: String!, $name: String!) {
        citiesJson(id: { eq: $id }) {
            department_code
            gps_lat
            gps_lng
            id
            code_insee
            name
            slug
            zip_code
        }
        allCitiesJson(
            filter: {
                name: { ne: $name }
                zip_code: {
                    in: [
                        "06700"
                        "13600"
                        "22000"
                        "24000"
                        "26100"
                        "28100"
                        "30100"
                        "31770"
                        "35400"
                        "37300"
                        "40000"
                        "44400"
                        "44800"
                        "47000"
                        "50100"
                        "54500"
                        "59400"
                        "59600"
                        "60100"
                        "62800"
                        "67300"
                        "69000"
                        "69140"
                        "69330"
                        "71000"
                        "74100"
                        "74200"
                        "76200"
                        "78130"
                        "78180"
                        "78190"
                        "78370"
                        "78400"
                        "78800"
                        "83700"
                        "86100"
                        "88000"
                        "89000"
                        "91120"
                        "91170"
                        "91200"
                        "92210"
                        "92240"
                        "93390"
                        "94130"
                        "94220"
                        "94240"
                        "94320"
                        "95190"
                        "95300"
                        "75000"
                        "31000"
                        "06000"
                        "44000"
                        "34000"
                        "59000"
                        "35000"
                        "76600"
                        "42000"
                        "83000"
                        "49000"
                        "30000"
                        "13080"
                        "29200"
                        "37000"
                        "92100"
                        "76000"
                        "54100"
                        "93100"
                        "86000"
                        "92400"
                        "92600"
                        "64000"
                        "34500"
                        "44600"
                        "29000"
                        "26000"
                        "69200"
                        "60155"
                        "83400"
                        "49300"
                        "13104"
                        "83600"
                        "93150"
                        "06130"
                        "74000"
                        "81990"
                        "64100"
                        "59300"
                        "34200"
                        "81100"
                        "62000"
                        "69120"
                        "69300"
                        "60200"
                        "92220"
                        "64600"
                        "42400"
                        "83140"
                        "92320"
                        "13000"
                        "67000"
                        "33000"
                        "51100"
                        "38000"
                        "21000"
                        "25000"
                        "95100"
                        "59100"
                        "59140"
                        "59200"
                        "06600"
                        "62100"
                        "06400"
                        "83500"
                        "95200"
                        "33600"
                        "94200"
                        "73000"
                        "79000"
                        "56100"
                        "95000"
                        "82000"
                        "02100"
                        "13117"
                        "13400"
                        "71100"
                        "62200"
                        "65000"
                        "78200"
                        "13118"
                        "06110"
                        "91300"
                        "59500"
                        "69800"
                        "93190"
                        "16000"
                        "59150"
                        "92230"
                        "93110"
                        "57100"
                        "94600"
                        "33400"
                        "28000"
                        "42300"
                        "83300"
                        "38400"
                        "38130"
                        "26200"
                        "67500"
                        "69100"
                        "72000"
                        "63000"
                        "87000"
                        "80000"
                        "57000"
                        "66000"
                        "45000"
                        "68100"
                        "14000"
                        "94500"
                        "17000"
                        "94100"
                        "68000"
                        "93700"
                        "18000"
                        "33700"
                        "92130"
                        "92300"
                        "59491"
                        "94700"
                        "77500"
                        "85000"
                        "92140"
                        "56000"
                        "91090"
                        "78500"
                        "11100"
                        "53000"
                        "77100"
                        "06800"
                        "92150"
                        "41000"
                        "36000"
                        "51000"
                        "92190"
                        "92800"
                        "94140"
                        "13300"
                        "91100"
                        "93120"
                        "78300"
                        "91600"
                        "58000"
                        "93420"
                        "77340"
                        "78700"
                        "69400"
                        "62300"
                        "13127"
                        "93200"
                        "94000"
                        "84000"
                        "92000"
                        "78000"
                        "94400"
                        "92700"
                        "93600"
                        "92500"
                        "93300"
                        "93160"
                        "92160"
                        "92200"
                        "10000"
                        "92110"
                        "94800"
                        "93800"
                        "93500"
                        "93140"
                        "94120"
                        "27000"
                        "90000"
                        "93270"
                        "08090"
                        "19100"
                        "92120"
                        "94300"
                        "93000"
                        "11000"
                        "93400"
                        "78100"
                        "01000"
                        "95140"
                        "05000"
                        "93130"
                        "77000"
                        "93220"
                        "59700"
                        "69500"
                        "03100"
                        "93290"
                        "13700"
                        "91700"
                        "93170"
                        "93240"
                        "93330"
                        "95130"
                        "94170"
                        "94190"
                        "92290"
                        "34500"
                        "30200"
                        "84100"
                        "31170"
                        "84200"
                        "38300"
                        "73100"
                    ]
                }
            }
        ) {
            edges {
                node {
                    code_insee
                    department_code
                    gps_lat
                    gps_lng
                    id
                    name
                    slug
                    zip_code
                }
            }
        }
        allPlomberieJson {
            edges {
                node {
                    id
                    code_insee
                    code_postal
                    ville
                }
            }
        }
        allElectriciteJson {
            edges {
                node {
                    id
                    code_insee
                    code_postal
                    ville
                }
            }
        }
        allPiscineJson {
            edges {
                node {
                    id
                    code_insee
                    code_postal
                    ville
                }
            }
        }
        allNuisibleJson {
            edges {
                node {
                    id
                    code_insee
                    code_postal
                    ville
                }
            }
        }
        allGuepesJson {
            edges {
                node {
                    id
                    code_insee
                    code_postal
                    ville
                }
            }
        }
    }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  line-height: 1.6rem;
  &:hover {
    color: red;
  }
  &:visited {
    color: ${(props) => props.theme.colors.primary}!important;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const StyledCitiesList = styled.div`
  columns: 2 auto;
  max-width: 650px;
  border: 1px solid #d1d7db;
  border-radius: 4px;
  padding: 1rem 0.8rem 1.2rem 0.8rem;

  @media only screen and ${breakpoint.device.sm} {
    columns: 1;
  }

  @media only screen and ${breakpoint.device.xs} {
    columns: 1;
  }
`;

const options = [
    { value: "plomberie", label: "Plomberie" },
    { value: "electricite", label: "Électricité" },
    { value: "gaz", label: "Gaz" },
    { value: "electromenager", label: "Électroménager" },
    { value: "nuisibles", label: "Dératisation" },
    { value: "nuisibles", label: "Destruction de nids de guêpes" },
];

const defaultValue = { value: "plomberie", label: "Plomberie" };

const Cities = ({ data }) => {
    const cp = data.citiesJson.department_code;
    const insee = data.citiesJson.code_insee;
    return (
        <Layout>
            <MetaModulo
                modulo={data.citiesJson.department_code % 5}
                cityName={data.citiesJson.name}
            />
            <Hero
                title={`<h1>Nos dépannages et petits travaux à ${data.citiesJson.name}</h1>`}
            >
                <FormHero options={options} defaultValue={defaultValue} />
            </Hero>
            <StickyBar defaultValue={defaultValue} options={options} />
            <ListCheck />
            <Separator />
            <Wrapper>
                <Title>
                    <h2>Nos offres de dépannage à {data.citiesJson.name}</h2>
                </Title>
                <StyledList>
                    {data.allPlomberieJson.edges
                        .filter((depannage) => depannage.node.code_insee.includes(insee))
                        .map((city, index) => {
                            return (
                                <li key={index}>
                                    <StyledLink to={`/${_.kebabCase(city.node.ville)}/plombier/`}>
                                        <Arrow style={{ marginRight: "1rem", fill: "#34e2d9" }} />
                                        Plombier {data.citiesJson.name}
                                    </StyledLink>
                                </li>
                            );
                        })}
                    {data.allElectriciteJson.edges
                        .filter((depannage) => depannage.node.code_insee.includes(insee))
                        .map((city, index) => {
                            return (
                                <li key={index}>
                                    <StyledLink
                                        to={`/${_.kebabCase(city.node.ville)}/electricien/`}
                                    >
                                        <Arrow style={{ marginRight: "1rem", fill: "#34e2d9" }} />
                                        Électricien {data.citiesJson.name}
                                    </StyledLink>
                                </li>
                            );
                        })}
                    {data.allPiscineJson.edges
                        .filter((depannage) => depannage.node.code_insee.includes(insee))
                        .map((city, index) => {
                            return (
                                <li key={index}>
                                    <StyledLink
                                        to={`/${_.kebabCase(city.node.ville)}/recherche-fuite-piscine/`}
                                    >
                                        <Arrow style={{ marginRight: "1rem", fill: "#34e2d9" }} />
                                        Recherche de fuite de piscine à {data.citiesJson.name}
                                    </StyledLink>
                                </li>
                            );
                        })}
                    {data.allNuisibleJson.edges
                        .filter((depannage) => depannage.node.code_insee.includes(insee))
                        .map((city, index) => {
                            return (
                                <li key={index}>
                                    <StyledLink
                                        to={`/${_.kebabCase(city.node.ville)}/deratisation/`}
                                    >
                                        <Arrow style={{ marginRight: "1rem", fill: "#34e2d9" }} />
                                        Dératisation à {data.citiesJson.name}
                                    </StyledLink>
                                </li>
                            );
                        })}
                    {data.allGuepesJson.edges
                        .filter((depannage) => depannage.node.code_insee.includes(insee))
                        .map((city, index) => {
                            return (
                                <li key={index}>
                                    <StyledLink
                                        to={`/${_.kebabCase(city.node.ville)}/destruction-nids-guepes-frelons/`}
                                    >
                                        <Arrow style={{ marginRight: "1rem", fill: "#34e2d9" }} />
                                        Destruction de nids de guêpes et frelons à {data.citiesJson.name}
                                    </StyledLink>
                                </li>
                            );
                        })}
                </StyledList>
            </Wrapper>
            <Separator />
            {data.allCitiesJson.edges.filter((city) =>
                city.node.department_code.includes(cp),
            ).length > 0 && (
                <Wrapper>
                    <Title>
                        <h2>
                            Nous intervenons également dans d'autres villes proches de vous
                        </h2>
                    </Title>
                    <StyledCitiesList>
                        {data.allCitiesJson.edges
                            .filter((city) => city.node.department_code.includes(cp))
                            .map((city, index) => {
                                return (
                                    <div key={index}>
                                        <StyledLink to={`/${_.kebabCase(city.node.slug)}/`}>
                                            {city.node.name}
                                        </StyledLink>
                                    </div>
                                );
                            })}
                    </StyledCitiesList>
                </Wrapper>
            )}
            <Avis />
        </Layout>
    );
};

export default Cities;
